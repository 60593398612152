import * as React from 'react';

import { graphql } from 'gatsby';
import {
    Avatar,
    Box,
    Button,
    Chip,
    ExpansionPanel,
    ExpansionPanelDetails,
    ExpansionPanelSummary,
    Grid,
    Paper,
    Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EmailIcon from '@material-ui/icons/Email';
import HomeIcon from '@material-ui/icons/Home';
import CodeIcon from '@material-ui/icons/Code';
import { FormattedDate, IntlProvider } from 'react-intl';
import Layout from '../components/layout';
import SEO from '../components/seo';
import EndAtDate from '../components/Job/EndAtDate';

interface PropsInterface {
    data: {
        allContentfulJob: ContentTypeInterface<JobInterface>;
        allContentfulTechnology: ContentTypeInterface<TechnologyInterface>;
    };
}

const openUrl = (url: string) => {
    window.open(url, '_blank');
};

const IndexPage: React.FC<PropsInterface> = ({ data }: PropsInterface) => {
    return (
        <IntlProvider locale="de">
            <Layout>
            <SEO title="Home" />
                <Box className="Content">
                    <Paper square className="Personal">
                        <Grid container spacing={2}>
                        <Grid item xs={3}>
                            <Avatar
                                  className="Avatar"
                                    alt="Christian Jost"
                                  src="/images/picture.jpg"
                                />
                          </Grid>
                        <Grid item xs={9}>
                            <h1>Christian Jost</h1>
                                <p>
                                    Currently working for{' '}
                                    <a
                                    href="https://schuettflix.de"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                        schuettflix.de
                                  </a>
                              </p>
                                <div>
                                    <Button
                                        variant="outlined"
                                        size="medium"
                                    endIcon={<CodeIcon />}
                                    className="ContactButton"
                                        onClick={() => openUrl('https://github.com/cjost1988')}
                                  >
                                        Github
                                  </Button>
                                <Button
                                      variant="outlined"
                                      size="medium"
                                      endIcon={<HomeIcon />}
                                        className="ContactButton"
                                      onClick={() => openUrl(
                                            'https://www.linkedin.com/in/christian-jost-0b5914135/'
                                        )}
                                    >
                                        LinkedIn
                                    </Button>
                                    <Button
                                    variant="outlined"
                                        size="medium"
                                        endIcon={<EmailIcon />}
                                        className="ContactButton"
                                    disabled
                                  >
                                        E-Mail
                                    </Button>
                              </div>
                          </Grid>
                      </Grid>
                  </Paper>

                <ExpansionPanel>
                      <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                            <Typography>Technologies</Typography>
                  </ExpansionPanelSummary>
                      <ExpansionPanelDetails className="Technologies">
                            <Typography component="div">
                          {data.allContentfulTechnology.edges.map(
                                    (
                                        technologyNode: ContentTypeEdgesInterface<
                                            TechnologyInterface
                                            >
                                    ) => (
                                        // eslint-disable-next-line react/jsx-key
                                        <Chip key={technologyNode.node.id} label={technologyNode.node.title} />
                                    )
                                )}
                        </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                <ExpansionPanel>
                    <ExpansionPanelSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                        >
                            <Typography>Career</Typography>
                        </ExpansionPanelSummary>
                    <ExpansionPanelDetails className="Career">
                    <Typography component="div">
                              <Grid container spacing={2}>
                                    {data.allContentfulJob.edges.map(
                                        (jobNode: ContentTypeEdgesInterface<JobInterface>) => {
                                            return [
                                                // eslint-disable-next-line react/jsx-key
                                                <Grid key={`${jobNode.node.id  }logo`} item xs={4}>
                                                    {jobNode.node.employer.logo && (
                                                        <img
                                                  src={jobNode.node.employer.logo.file.url}
                                                  alt={jobNode.node.employer.logo.title}
                                                            className="EmployerLogo"
                                                />
                                                    )}
                                              </Grid>,
                                                <Grid key={`${jobNode.node.id  }description`} item xs={8}>
                                                <b>{jobNode.node.position}</b>
                                                    <br />
                                                    at {jobNode.node.employer.name}
                                                    <br />
                                                <span>
                                                      <FormattedDate
                                                            value={jobNode.node.startedAt}
                                                    month="long"
                                                    year="numeric"
                                                  />{' '}
                                                        -{' '}
                                                        <EndAtDate date={jobNode.node.endedAt} />
                                                    </span>
                                              </Grid>,
                                            ];
                                        }
                                    )}
                                </Grid>
                            </Typography>
                        </ExpansionPanelDetails>
                    </ExpansionPanel>
                    <ExpansionPanel>
                        <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel3a-content"
                        id="panel3a-header"
                      >
                            <Typography>Techstack of this page</Typography>
                      </ExpansionPanelSummary>
                        <ExpansionPanelDetails>
                        <ul>
                                <li>Content is managed in prismic.io (headless CMS)</li>
                                <li>Content is consumed using graphql</li>
                                <li>Static Site Generator Gatsby builds page</li>
                                <li>Hosting is provided by Vercel</li>
                                <li>Page will be rebuild by Vercel on several triggers (GIT repository changes, CMS callbacks)</li>
                      </ul>
                        </ExpansionPanelDetails>
                  </ExpansionPanel>
              </Box>
          </Layout>
      </IntlProvider>
    );
};

export const query = graphql`
  query MyQuery {
    allContentfulTechnology {
      edges {
        node {
          id
          title
        }
      }
    }
    allContentfulJob(sort: { fields: startedAt, order: DESC }) {
      edges {
        node {
          employer {
            name
            website
            id
            logo {
              title
              file {
                url
              }
            }
          }
          position
          startedAt
          endedAt
        }
      }
    }
  }
`;

export default IndexPage;
