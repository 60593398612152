import React from 'react';
import { FormattedDate } from 'react-intl';

interface PropsInterface {
    date: string | null;
}
const EndAtDate = ({ date }: PropsInterface) => {
    if (date === null) {
        return <span>today</span>;
    }

    return <FormattedDate value={date} month="long" year="numeric" />;
};

export default EndAtDate;
